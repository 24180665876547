import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { images } from "../../assets/exportImgs";

function PageNotFound() {
  const location = useLocation();
  const navigate = useNavigate();
  const definedRoutes = [
    "/avua-pool",
    "/search-history",
    "/cv-masking",
    "/pricing",
    "/employer/dashboard",
    "/employer/company",
    "/employer/messages",
    "/employer/package",
    "/employer/jobsspace",
    "/employer/jobsspace/jobs",
    "/employer/jobsspace/jobs/candidates",
    "/employer/settings",
    "/users/email-unsubscribe",
    // "/blogs"
  ];

  useEffect(() => {
    if (definedRoutes?.includes(location?.pathname)) {
      return navigate("/");
    }
  }, [location?.pathname]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 px-[30px]">
      <img className="h-[163px]" src={images.page_not_found.page_404} />
      <div className="text-center mt-[31px] xl:mx-[400px]">
        <h1 className="text-[30px] font-poppins font-[500] text-gray-800 mb-[15px]">
          Oops! Page Not Found
        </h1>
        <p className="text-[18px] font-poppins font-[400] mb-[15px] text-[#424242]">
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </p>
        <div>
          <button onClick={() => navigate("/")}>
            <img src={images?.page_not_found?.go_home} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
