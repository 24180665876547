import { isEmptyChildren } from "formik";
import { api } from "../services";

//GET Location Search
export const getLocationSearch = (place, searchInCountries = "", token) => {
  // Define the API endpoint with the "q" query parameter
  const endpoint = `/autofill/external/v1/cities?q=${place}${
    searchInCountries ? `&country_code=${searchInCountries}` : ""
  }`;

  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

export const getLocationByCountryCode = async (query, code, token) => {
  try {
    const res = await api.get(
      `/autofill/external/v1/cities?q=${query}&country_code=${code}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCountry = async (query, token) => {
  const endpoint = `autofill/external/v1/nationality?q=${query}`;
  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

export const getCountryByCode = async (code, token) => {
  const endpoint = `autofill/external/v1/country?country_code=${code}`;
  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};
//GET presigned url
export const getPresignedURL = (file_name, file_type, token) => {
  const endpoint = `/attachment/external/v1/presigned-url?file-name=${file_name}&type=${file_type}`;

  // Construct headers conditionally
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  return api.get(endpoint, { headers });
};

// Function to Get all files uploaded resume details
export const getResume = async (token) => {
  try {
    const response = await api.get(`/applicant/external/v1/resumes`, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the bearer token
      },
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error("File upload failed:", error);
  }
};

// Function to upload a resume PDF file
export const uploadResume = async (info, token) => {
  try {
    // const formData = new FormData();
    // formData.append("File", file); // Append the single file

    const response = await api.patch(`/applicant/external/v1/resumes`, info, {
      headers: {
        Authorization: `Bearer ${token}`, // Add the bearer token
      },
    });

    console.log("File upload successful:", response.data);
    return response.data; // Return the response data
  } catch (error) {
    // Handle errors
    console.error("File upload failed:", error);
    throw error; // Re-throw the error to handle it elsewhere
  }
};

// GET parse resume
export const getParseResume = (resumeLink, token) => {
  // Define the API endpoint with the "resume-link" query parameter
  const endpoint = `/applicant/external/v1/resume-parser?resume-link=${resumeLink}`;

  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

// *********************** PROFILE *******************************

//GET Basic profile
export const getBasicProfile = (token) => {
  return api.get("/applicant/external/v1/basic-profile", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//PATCH Basic profile
export const patchBasicProfile = (userData, token) => {
  return api.patch(`/applicant/external/v1/basic-profile`, userData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

//Image upload

// *********************** CONTACT INFORMATION *******************************

//GET contact infomation
export const getContactInformation = (token) => {
  return api.get("/applicant/external/v1/contact-information", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// POST contact information
export const postContactInformation = (contactInfoData, token) => {
  return api.post(
    "/applicant/external/v1/contact-information",
    contactInfoData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

// PATCH contact information
export const patchContactInformation = (contactInfoData, token) => {
  return api.patch(
    "/applicant/external/v1/contact-information",
    contactInfoData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

// *********************** JOB PREFERENCES *******************************

// GET job preferences

export const getJobPreferences = (token) => {
  //REMOVE
  // return getJobPrefDummyData

  return api.get("/applicant/external/v1/job-preference", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// POST preferences
export const postJobPreferences = (preferencesData, token) => {
  return api.post("/applicant/external/v1/job-preference", preferencesData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// PATCH preferences
export const patchJobPreferences = (preferencesData, token) => {
  return api.patch("/applicant/external/v1/job-preference", preferencesData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// DELETE preference by ID
export const deleteJobPreference = (preferenceId, token) => {
  return api.delete(
    `/applicant/external/v1/job-preference?id=${preferenceId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

// GET Currencies
export const getCurrencies = (token) => {
  return api.get("/autofill/external/v1/currencies", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// GET Languages
export const getLanguages = (language, token) => {
  // Define the API endpoint with the "language" query parameter
  const endpoint = `/autofill/external/v1/languages?language=${language}`;
  return api.get(endpoint, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// GET job titles -> check WORK EXP

// *********************** WORK EXPERIENCE *******************************

//Get Experience
export const getWorkExperience = (token) => {
  return api.get("/applicant/external/v1/work-experience", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// GET companies
export const getCompanies = (query, token) => {
  // Using URLSearchParams for proper encoding
  const params = new URLSearchParams({ q: query });

  // Construct the API endpoint with encoded query parameters
  const endpoint = `/autofill/external/v1/companies?${params.toString()}`;

  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

// GET job titles by title query
export const getJobTitles = (title, token) => {
  // Defineing the API endpoint and including the query parameter "title"
  // return dummyJobTitles
  const params = new URLSearchParams({ title });
  const endpoint = `/autofill/external/v1/job-titles?${params.toString()}`;
  return api.get(
    endpoint
    // {headers: { Authorization: `Bearer ${token}` },}
  );
};
export const getJobTilteByCategoryId = (category_id) => {
  // Defineing the API endpoint and including the query parameter "title"
  // return dummyJobTitles
  const endpoint = `/autofill/external/v1/category/job-titles?category_id=${category_id}`;
  return api.get(
    endpoint
    // {headers: { Authorization: `Bearer ${token}` },}
  );
};

//Patch Experience
export const patchWorkExperience = async (experienceData, token) => {
  try {
    const response = await api.patch(
      `/applicant/external/v1/work-experience`,
      experienceData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Post Experience
export const postWorkExperience = async (experienceData, token) => {
  try {
    const response = await api.post(
      `/applicant/external/v1/work-experience`,
      experienceData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// DELETE work experience by ID
export const deleteWorkExperience = async (experienceId, token) => {
  try {
    // Make an API request to delete the work experience with the given ID
    const response = await api.delete(
      `/applicant/external/v1/work-experience?id=${experienceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Return a success message or data upon successful deletion
  } catch (error) {
    throw error; // Handle errors as needed
  }
};

export const updateExperienced = async (isExperienced, token) => {
  try {
    const response = await api.patch(
      `/applicant/external/v1/update-experienced-status?is-experienced=${isExperienced}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getExperienced = async (token) => {
  try {
    const response = await api.get(`applicant/external/v1/is-experienced`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Comapny:", error);
    throw error;
  }
};

// GET 5 skill suggestions by query (job_role)
export const getSkillsSuggestions = (query, token) => {
  // Using URLSearchParams for proper encoding
  const params = new URLSearchParams({ title: query });

  // Construct the API endpoint with encoded query parameters
  const endpoint = `/applicant/external/v1/scaling/skills?${params.toString()}`;
  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

// GET search for skill suggestion list
export const getSkillSuggestions = (name, token) => {
  // Using URLSearchParams for proper encoding
  const params = new URLSearchParams({ name });
  // Construct the API endpoint with encoded query parameters
  const endpoint = `/autofill/external/v1/skills?${params.toString()}`;
  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

// ************************ EDUCATION *******************************

// GET job titles by title query
export const getUserEducationDetails = (token) => {
  const endpoint = `/applicant/external/v1/educational-details`;
  return api.get(endpoint, { headers: { Authorization: `Bearer ${token}` } });
};

// GET qualification
export const getQualifications = (name, token) => {
  // Define the API endpoint with the "name" query parameter
  const endpoint = `/autofill/external/v1/education-details?title=${name}`;
  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

// GET field of study
export const getFieldOfStudy = (field, token) => {
  // Using URLSearchParams for proper encoding
  const params = new URLSearchParams({ field });
  // Construct the API endpoint with encoded query parameters
  const endpoint = `/autofill/external/v1/field-of-study?${params.toString()}`;
  return api.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

// PATCH education exp
export const patchEducation = async (educationData, token) => {
  try {
    const response = await api.patch(
      `/applicant/external/v1/educational-details`,
      educationData,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Post education exp
export const postEducation = async (educationData, token) => {
  try {
    const response = await api.post(
      `/applicant/external/v1/educational-details`,
      educationData,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// DEL education exp
export const deleteEducationExperience = async (experienceId, token) => {
  try {
    // Make an API request to delete the work experience with the given ID
    const response = await api.delete(
      `/applicant/external/v1/educational-details?id=${experienceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Return a success message or data upon successful deletion
  } catch (error) {
    throw error; // Handle errors as needed
  }
};

// ************************ ADDITIONAL *******************************

// GET ADDITIONAL info
export const getAdditionalUserInfo = (token) => {
  // Define the API endpoint with the "field" query parameter
  // return  dummyAddExp
  return api.get("/applicant/external/v1/additional-data", {
    headers: {
      Authorization: `Bearer ${token}`, // Include the bearer token
    },
  });
};

// PATCH Additional Data
export const patchAdditionalUserInfo = async (additionalData, token) => {
  try {
    const response = await api.patch(
      "/applicant/external/v1/additional-data",
      additionalData,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include the bearer token
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// POST Additional Data
export const postAdditionalUserInfo = async (additionalData, token) => {
  try {
    const response = await api.post(
      "/applicant/external/v1/additional-data",
      additionalData,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include the bearer token
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postCvAnalysis = async (data, token) => {
  try {
    const response = await api.post(
      "/applicant/external/v1/cv-analysis/",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postFreeCvAnalysis = async (data) => {
  try {
    const response = await api.post(
      "/applicant/external/v1/cv-analysis/free-analysis",
      data,
      {
        // headers: {
        //   Authorization: {},
        // },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCv = async (data, id, token) => {
  try {
    const response = await api.patch(
      `/applicant/external/v1/cv-analysis/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reUpdateAnalysisData = async (data, id, token) => {
  try {
    const response = await api.patch(
      `/applicant/external/v1/cv-analysis/recalculate-analysis/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getResumeWithAi = async (id, data, token) => {
  try {
    const response = await api.post(
      `/applicant/external/v1/cv-analysis/rewrite-cv/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCvAnalysisById = async (id, token, fromUpgrade = false) => {
  try {
    const response = await api.get(
      fromUpgrade
        ? `/applicant/external/v1/cv-analysis/${id}?is_trial_upgrade=true`
        : `/applicant/external/v1/cv-analysis/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {}
};

export const getCvAnalysisList = async (token, limit, offset) => {
  try {
    const response = await api.get(
      `/applicant/external/v1/cv-analysis/list/?limit=${limit}&offset=${offset}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Comapny:", error);
    throw error;
  }
};

export const getCustomJd = async (path, token) => {
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  try {
    const response = await api.get(
      `/applicant/external/v1/cv-analysis/generate-jd/?cv_path=${path}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Comapny:", error);
    throw error;
  }
};

export const deleteScanHistoty = (id, token) => {
  try {
    return api.delete(`/applicant/external/v1/cv-analysis/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const postCoverLetter = async (id, body, token) => {
  try {
    const response = await api.post(
      `/applicant/external/v1/cv-analysis/cover-letter/generate/${id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getParseResumeData = async (path, token) => {
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  try {
    const response = await api.get(
      `/applicant/external/v1/cv-analysis/parse-resume?cv_path=${path}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Comapny:", error);
    throw error;
  }
};
