import { RxCross1 } from "react-icons/rx";
import { PiWarning } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
export const SuccessInvitationModal = ({
  setShowSuccessModel,
  setIsInviteModalOpen,
  pdfUrl,
}) => {
  console.log("THIS IS MY PDF URL", pdfUrl);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      {/* Background Blur */}
      <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm"></div>

      {/* Modal Content */}
      <div className="relative z-10 bg-[#F8F9FE] w-[80%] h-[90%] flex flex-col justify-center gap-[50px] items-center p-[68px] rounded-lg shadow-lg text-center">
        {/* Close Icon */}
        <div
          className="absolute top-[15px] right-[15px] h-[35px] w-[35px] bg-[#F8F9FE] flex justify-center items-center rounded-full border border-black cursor-pointer"
          onClick={() => {
            setShowSuccessModel(false);
            setIsInviteModalOpen(false);
          }}
        >
          <RxCross1 className="text-[18px]" />
        </div>
        {pdfUrl && (
          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="600px"
          >
            <p>
              Unable to display PDF.{" "}
              <a href={pdfUrl} download>
                Download it
              </a>{" "}
              instead.
            </p>
          </object>
        )}
      </div>
    </div>
  );
};

export const ParserErrorModal = ({
  setShowSuccessModel,
  fetchCvAnalysis,
  handleNavigation,
  errorMessage = "",
  fromIndependentFlow = false,
}) => {
  const navigate = useNavigate();
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50  ">
      {/* Background Blur */}
      <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm"></div>

      {/* Modal Content */}
      <div className="relative z-10 bg-white w-fit md:max-w-[500px] flex flex-col justify-center gap-[24px] items-center py-[24px] px-[60px] rounded-lg shadow-lg text-center">
        {/* Modal Body */}
        <div className="flex flex-col justify-center items-center gap-[16px]">
          <PiWarning className="w-[36px] h-[36px] text-[#00000066]" />
          {errorMessage !== "Insufficient credits" && (
            <h3 className="font-poppins font-[600] text-[20px] text-[#000000]   leading-[30px] ">
              {errorMessage ? errorMessage : "Something went wrong"}
            </h3>
          )}

          {errorMessage === "Something went wrong" ? (
            <p className="font-poppins font-[400] text-[16px] text-[#000000B2]  leading-[24px]  ">
              We are having some problems with <br /> analyzing your CV.
            </p>
          ) : (
            <>
              {errorMessage === "Please upload a valid resume" && (
                <p className="font-poppins font-[400] text-[16px] text-[#000000B2]  leading-[24px] text-center">
                  We couldn’t extract text from your file. It might be scanned
                  or corrupted. Try again with a different file.
                </p>
              )}
            </>
          )}

          {errorMessage === "Insufficient credits" && (
            <p className="font-poppins font-[400] text-[16px] text-[#000000B2]  leading-[24px] text-center">
              {errorMessage}
            </p>
          )}
        </div>

        {/* Buttons */}
        <div className="flex justify-between items-center w-full gap-[20px] whitespace-nowrap">
          <button
            className="flex text-poppins  justify-center items-center  px-[30px] py-[13px] border-[1px] border-[#D9D9D9] text-[#000000B2] text-[16px] leading-[19.2px] font-[500] rounded-l-full rounded-r-full"
            onClick={() => {
              setShowSuccessModel(false);
              handleNavigation();
            }}
          >
            Go to Home
          </button>
          <button
            className="flex text-poppins  justify-center items-center px-[30px] py-[13px] bg-gradient-to-r from-[#864EFF] to-[#502F99] text-white text-[16px] leading-[19.2px] font-[500] rounded-l-full rounded-r-full"
            onClick={() => {
              if (errorMessage === "Something went wrong") {
                fetchCvAnalysis();
                setShowSuccessModel(false);
              } else if (errorMessage === "Insufficient credits") {
                navigate("/applicant/pricing", {
                  state: {
                    fromPricingError: !!fromIndependentFlow ? true : false,
                  },
                });
              } else {
                handleNavigation();
              }
            }}
          >
            {errorMessage === "Insufficient credits"
              ? "Upgrade to Premium"
              : "Try Again"}
          </button>
        </div>
      </div>
    </div>
  );
};
export const ParserErrorModalResume = ({
  setShowSuccessModel,
  handleNavigation,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      {/* Background Blur */}
      <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm"></div>

      {/* Modal Content */}
      <div className="relative z-10 bg-white flex flex-col justify-center gap-[24px] items-center py-[24px] px-[60px] rounded-lg shadow-lg text-center">
        {/* Modal Body */}
        <div className="flex flex-col justify-center items-center gap-[16px]">
          <PiWarning className="w-[36px] h-[36px] text-[#00000066]" />
          <h3 className="font-poppins font-[600] text-[20px] text-[#000000]   leading-[30px] ">
            Something went wrong
          </h3>
          <p className="font-poppins font-[400] text-[16px] text-[#000000B2]  leading-[24px]  ">
            We are having some problems with <br /> analyzing your resume.
          </p>
        </div>

        {/* Buttons */}
        <div className="flex justify-center items-center w-full gap-[20px]">
          <button
            className="flex text-poppins  justify-center items-center px-[30px] py-[13px] bg-gradient-to-r from-[#864EFF] to-[#502F99] text-white text-[16px] leading-[19.2px] font-[500] rounded-l-full rounded-r-full"
            onClick={() => {
              handleNavigation();
            }}
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  );
};
