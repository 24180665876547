import { useState, useEffect } from "react";
import { Puff } from "react-loader-spinner";
import { useMediaQuery } from "react-responsive";

const ProgressBarLoading = () => {
  const steps = [
    { id: 1, progress: "Parsing information" },
    { id: 2, progress: "Evaluating impact" },
    { id: 3, progress: "Identifying core sections" },
    { id: 4, progress: "Preparing CV insights" },
    { id: 5, progress: "Successfully done" },
  ];

  const [loadingData, setLoadingData] = useState(steps[0].progress);
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    let index = 0;

    const updateStep = () => {
      if (index < steps.length) {
        setLoadingData(steps[index].progress);
        setProgress((index / (steps.length - 1)) * 100);
        setCurrentStep(index);

        if (index < steps.length - 1) {
          setTimeout(() => {
            index++;
            updateStep();
          }, 3000);
        }
      }
    };
    updateStep();
    return () => clearTimeout(updateStep);
  }, []);

  return (
    <>
      {isSmallScreen ? (
        <div className="w-full flex  justify-center items-center">
          <div className="flex flex-row gap-10 p-5">
            {/* Progress bar and indicators */}
            <div className="relative flex flex-col items-center">
              {/* Progress bar */}
              <div className="absolute top-0 bottom-0 left-1/2 w-2 bg-gradient-to-b from-[#D9D9D9] to-[#E8DEF8] rounded-full z-0 transform -translate-x-1/2">
                <div
                  className="bg-gradient-to-b from-[#864EFF] to-[#502F99] rounded-full transition-all duration-500"
                  style={{ height: `${progress}%` }}
                ></div>
              </div>

              {/* Indicators */}
              <div className="flex flex-col justify-between h-full  relative z-10 ">
                {steps.map((step, index) => (
                  <div key={step.id}>
                    <div
                      className={`w-10 h-10 rounded-full flex items-center justify-center border bg-white border-[#E8DEF8] z-20`}
                    >
                      {index < currentStep ? (
                        <span className="text-[#242424] text-lg font-bold">
                          ✓
                        </span>
                      ) : index === currentStep ? (
                        <Puff
                          visible={true}
                          height="30"
                          width="30"
                          color="#502F99"
                          ariaLabel="puff-loading"
                        />
                      ) : (
                        <span className="text-[#242424] text-lg font-bold">
                          {step.id}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Progress text */}
            <div className="flex flex-col items-start gap-5">
              {steps.map((step, index) => (
                <p
                  className={`text-[16px] leading-[19.2px] font-poppins font-[500] text-center max-w-[109px] mt-2 ${
                    loadingData === step.progress
                      ? "text-[#2F2F2F]"
                      : "text-[#6C6C6E]"
                  }`}
                >
                  {step.progress}
                </p>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative w-full flex items-center">
          <div className="relative bg-gradient-to-r from-[#D9D9D9] to-[#E8DEF8] rounded-full overflow-hidden z-0">
            <div
              className="bg-gradient-to-r from-[#864EFF] to-[#502F99] rounded-full transition-all duration-500"
              style={{ width: `${progress}%`, height: "100%" }}
            ></div>
          </div>

          <div className="absolute top-[16px] left-[5%] w-[90%] h-[2px] bg-gradient-to-r from-[#D9D9D9] to-[#E8DEF8] rounded-full overflow-hidden z-0">
            <div
              className="h-2 bg-gradient-to-r from-[#864EFF] to-[#502F99] rounded-full transition-all duration-500"
              style={{ width: `${progress}%` }}
            ></div>
          </div>

          <div className="relative flex justify-between w-full z-10">
            {steps.map((step, index) => (
              <div key={step.id} className="flex flex-col items-center">
                <div
                  className={`w-10 h-10 rounded-full flex items-center justify-center border bg-white border-[#E8DEF8] z-20`}
                >
                  {index < currentStep ? (
                    <span className="text-[#242424] text-lg font-bold">✓</span>
                  ) : index === currentStep ? (
                    <Puff
                      visible={true}
                      height="30"
                      width="30"
                      color="#502F99"
                      ariaLabel="puff-loading"
                    />
                  ) : (
                    <span className="text-[#242424] text-lg font-bold">
                      {step.id}
                    </span>
                  )}
                </div>
                <p
                  className={`text-[16px] leading-[19.2px] font-poppins font-[500] text-center max-w-[109px] mt-2 ${
                    loadingData === step.progress
                      ? "text-[#2F2F2F]"
                      : "text-[#6C6C6E]"
                  }`}
                >
                  {step.progress}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ProgressBarLoading;
