import React, { useEffect, useRef, useState } from "react";
import { ParserErrorModal } from "../../micros/Modals/GenericModals";
import ProgressBarLoading from "../../micros/cv_analysis/ProgressBarLoading";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { animations } from "../../../../assets/exportImgs";
import axios from "axios";
import Lottie from "lottie-react";
import {
  getParseResumeData,
  postCvAnalysis,
  postFreeCvAnalysis,
} from "../../../../api/resBuilder/resumeBuilder";
import uuid4 from "uuid4";
import { setResults } from "../../../../stores/slices/cvAnalysisSlice";
import { basicProfile } from "../../../../api/services";
import { setPremiumApplicant } from "../../../../stores/slices/authSlice";

const LoadingScreen = ({ setStep }) => {
  const [animation, setAnimation] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRenderedOnce = useRef(false);
  const accessToken = useSelector((state) => state.auth.token);
  const location = useLocation();
  const analysisData = location?.state?.data ?? null;
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const getLottie = async () => {
    try {
      const response = await axios.get(
        animations.Cv_analysis.cvUploading_analysis
      );
      setAnimation(response?.data);
    } catch (error) {
      console.error("Error fetching Lottie animation:", error);
    }
  };

  const getUserinfo = async () => {
    try {
      const userData = await basicProfile(accessToken);
      if (userData?.request?.status === 200) {
        dispatch(
          setPremiumApplicant(
            userData?.data?.data?.is_premium_applicant ?? false
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCvAnalysis = async () => {
    let Response = null;
    const resumePath = analysisData?.resumeDetails?.path;
    setLoading(true);

    // Get session ID from local storage or generate a new one
    let sessionId = localStorage.getItem("sessionId");
    if (!sessionId) {
      sessionId = uuid4();
      localStorage.setItem("sessionId", sessionId);
    }

    try {
      const res = await getParseResumeData(resumePath);
      if (res.msg === "success") {
        Response = res.data;
      }
    } catch (error) {
      console.log(error);

      if (error?.status === 400) {
        setError("Please upload a valid resume");
        setErrorModal(true);
      } else if (
        error.status === 500 &&
        typeof error.response?.data?.msg?.error === "string" &&
        error.response.data.msg.error
          .toLowerCase()
          .includes("invalid json response")
      ) {
        console.log("Invalid JSON response detected.");
        setError("Something went wrong");
        setErrorModal(true);
      } else if (error.status === 500) {
        setError("Something went wrong");
        setErrorModal(true);
      }
      return;
    }

    // Base payload data
    const basePayload = {
      cv_data: {
        file: {
          type: analysisData?.resumeDetails?.type,
          path: analysisData?.resumeDetails?.path,
          size: analysisData?.resumeDetails?.size,
        },
        parse_data: JSON.stringify(Response),
      },
      jd_data:
        analysisData?.selectedOption === "Compare with Job Description"
          ? {
              file: !!analysisData?.jdDetails
                ? {
                    type: analysisData?.jdDetails?.type,
                    path: analysisData?.jdDetails?.path,
                    size: analysisData?.jdDetails?.size,
                  }
                : null,
              custom_data: !!analysisData?.jdDetails
                ? null
                : analysisData?.selectedJobTitles?.job_title ||
                  analysisData?.roles
                ? {
                    job_title: !!analysisData?.selectedJobTitles?.job_title
                      ? analysisData?.selectedJobTitles?.job_title
                      : null,
                    roles_and_responsibilities:
                      analysisData?.selectedJobTitles
                        ?.roles_and_responsibilities || analysisData?.roles,
                  }
                : null,
            }
          : null,
    };

    try {
      let res;

      // Check if user is authenticated
      if (isAuthenticated) {
        // For authenticated users, iuse the base payload without session ID
        res = await postCvAnalysis(basePayload, accessToken);
      } else {
        const authPayload = {
          ...basePayload,
          session_id: sessionId,
        };
        // For free users, use the base payload with session ID
        res = await postFreeCvAnalysis(authPayload);
      }
      if (res.msg === "success") {
        const cvData = res.data;
        dispatch(
          setResults({
            results: cvData?.cv_analysis?.results,
            Cv_url: cvData?.cv_data?.file?.url,
            cv_id: cvData?.id,
            cv_name: analysisData?.prviewFile?.name,
            parse_data: cvData?.cv_data?.parse_data,
            resumeData: cvData?.cv_data?.parse_data,
            jd_data: cvData?.jd_data,
            is_trial: cvData?.is_trial,
            cvFile: cvData?.cv_data?.file,
          })
        );
        navigate("/applicant/cv-analysis/report");
      }
    } catch (error) {
      const errorMsg =
        error.response?.data?.msg?.err === "insufficient credits"
          ? "Insufficient credits"
          : "Something went wrong";
      setError(errorMsg);
      setErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLottie();
    if (!isRenderedOnce.current) {
      fetchCvAnalysis();
      isRenderedOnce.current = true;
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getUserinfo();
    }
  }, []);

  const handleNavigation = () => {
    navigate("/applicant/growth-plan");
  };

  return (
    <div className=" w-full h-screen flex justify-center items-center  ">
      {errorModal && error && (
        <ParserErrorModal
          handleNavigation={handleNavigation}
          setShowSuccessModel={setErrorModal}
          fetchCvAnalysis={fetchCvAnalysis}
          errorMessage={error}
          fromIndependentFlow={true}
        />
      )}
      {!!loading && (
        <div className="md:w-[710px]   w-full flex  flex-col gap-[30px] items-center">
          <div className=" text-center">
            <p className=" text-[35px] font-[500] leading-[38.4px] font-poppins text-[#000000]">
              Please Wait
            </p>
            <p className=" text-[16px] leading-[19.2px] text-[#000000] font-poppins">
              This may take upto 60 seconds to properly analyze your CV
            </p>
          </div>
          <div className=" h-[242px]">
            <Lottie animationData={animation} loop={false} />
          </div>

          <div className="w-full flex justify-between items-center ">
            <ProgressBarLoading />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;
